<template>
  <v-container>
    <v-row>
      <v-col cols="12 my-2">
        <h3>Connect Bank Your Account(s) for payment from TakeIn Platform.</h3>
      </v-col>
    </v-row>

    <template v-if="!loading">
      <template v-if="account">
        <v-card height="100">
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="10">
                Account Type:
                <b>{{ accountType }}</b>
              </v-col>

              <v-col cols="12" sm="2">
                <v-btn color="error" small @click="deleteAccount"
                  >Delete account</v-btn
                >
              </v-col>
              <v-col v-if="accountType === 'Standard'">
                <v-list-item-title>
                  Managed by
                  <a
                    target="_blank"
                    href="https://connect.stripe.com/account/applications"
                  >
                    <v-icon color="blue lighten-2" left>fab fa-stripe</v-icon>
                  </a>
                </v-list-item-title>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-progress-circular
          v-if="localLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>

        <v-row>
          <v-col cols="12 my-2">
            <v-btn to="/account/transactions">Payment Transactions</v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="12">
            Setup your bank account using
            <a target="_blank" :href="callBackUrl">
              <v-btn color="blue lighten-2">
                <v-icon large left>fab fa-stripe</v-icon>
              </v-btn>
            </a>
          </v-col>
          <v-col cols="12" v-if="false">
            <a target="_blank" :href="callBackUrlForCode">Link</a> if you have
            existing Stripe account.
          </v-col>
        </v-row>
      </template>
    </template>
    <template v-else>
      <v-col cols="12">
        <v-alert outlined color="error" icon="warning" :value="true"
          >Your account is not verified yet.</v-alert
        >
      </v-col>
    </template>
    <v-row>
      <v-row justify="end">
        <v-col cols="4" md="2">
          <a href="https://stripe.com" target="_blank">
            <img height="30" src="/powered_by_stripe.png" />
          </a>
        </v-col>
      </v-row>
    </v-row>
    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Deleting Bank Account"
      message="Are you sure you want to delete this account?"
      @onConfirm="onDeleteAccount"
    ></delete-confirmation-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      localLoading: false
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      account: 'bankAccount'
    }),
    callBackUrl() {
      const domain = process.env.VUE_APP_DOMAIN
      const client_id = process.env.VUE_APP_STRIPE_CLIENT_ID
      return `https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://biz.${domain}/callback/account&client_id=${client_id}&state=${this.profile.id}`
    },
    callBackUrlForCode() {
      const client_id = process.env.VUE_APP_STRIPE_CLIENT_ID
      return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write&state=${this.profile.id}`
    },

    accountType() {
      if (this.account) {
        switch (this.account.scope) {
          case 'express':
            return 'Express'
          case 'read_write':
            return 'Standard'

          default:
            return 'Custom'
        }
      }
    }
  },

  mounted() {
    if (!this.profile) {
      console.warn('Profile is missing')
      return
    }
  },
  methods: {
    getFileName(month) {
      return month + '_' + this.profile.name + '_financial_data.csv'
    },

    deleteAccount(id) {
      this.id = id
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeleteAccount() {
      this.$refs.deleteConfirmationDialog.close()
      this.$store.dispatch('deleteBankAccount', {
        uid: this.profile.id,
        id: this.account.stripe_user_id
      })
    }
  }
}
</script>
